import React from "react";
import {generateCSSString} from "../../../helper/styleHelper";
import Button from "../../Button";
import Title from "../../Title";


export default function DefaultCard(props) {

    const elem = props.elem;

    const cssValue = ['height', 'borderRadius', 'alignment', 'justifyContent'];

    const cssValueInnerPart = ['marginTop', 'marginRight',  'marginLeft', 'marginBottom', 'paddingTop', 'paddingRight', 'paddingLeft'];

    const cssTitle = ['fontSize']

    return (
            <div id={`BgImageCard-${elem.uuid}`} 
                className={`${generateCSSString(elem, cssValue)} overflow-hidden card-shadow`} 
                style={{backgroundColor: elem.backgroundColor}}
            >
                <div className='relative'>

                    <img src={`${elem.image}`} className='w-full object-cover' style={{maxHeight: '210px'}}/>

                    <div className='absolute w-full inset-0'
                        style={{
                            background: `${elem.overlay? 'linear-gradient(rgba(34, 0, 76, 0) 0%, rgba(34, 0, 76, 0.3) 100%) 0% 0% no-repeat padding-box padding-box' : 'none'}`,
                        }}

                    ></div>
                </div>
        
                <div className={`${generateCSSString(elem, cssValueInnerPart)} pb-${elem.paddingTop.slice(3)} md:${elem.paddingBottom}`}>
                    
                    {/* Title */}
                    <div className={`mb-2 ${generateCSSString(elem, cssTitle)} `}>
                        <Title elem={{...elem, 
                            textDecoration: 'font-bold', 
                            paddingTop    : 'pt-0',
                            paddingBottom : 'pb-0',
                            paddingLeft   : 'pl-0',
                            paddingRight  : 'pr-0',}}/>
                    </div>

                    {/* Content */}
                    <div className={`${elem.buttonTitle && !props.mobileView? 'md:mb-10' : ''}`} dangerouslySetInnerHTML={{__html: elem.content}}/>

                    {/* Button */}
                    {!props.mobileView && elem.buttonTitle &&
                    <div className='flex justify-center mt-auto'>
                        <Button 
                        type='card' 
                        elem={{
                            type: 'Button', 
                            content: elem.buttonTitle, 
                            color: elem.butonColor, 
                            hoverColor: elem.hoverColor,
                            backgroundColor: elem.buttonBackgroundColor,
                            hoverBackgroundColor: elem.hoverBackgroundColor,
                            borderColor: elem.borderColor,
                            hoverBorderColor: elem.hoverBorderColor,
                            alignment: elem.alignment === 'text-center'? 'justify-center': elem.alignment === 'text-right'? 'justify-end' : 'justify-start', 
                            link: elem.link, 
                            target: elem.target}}
                        target={props.target}
                        />
                    </div>}
                </div>
            </div>
    )
}