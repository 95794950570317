import * as React from "react";
import * as ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import {LanguageProvider} from "./libs/LanguageProvider";
import axios from "axios"
import {ModalProvider} from "./libs/ModalProvider";
import Login from "./views/Login"
import {getUserLanguages} from "./helper/User";

export const fetchTranslations = ({language = "de"}) =>
    axios.get("/design/frontend/language/" + getUserLanguages() + ".json")
        .then((data) => {
            return data.data;
        });


if (document.getElementById("app") !== null) {
    ReactDOM.render(
        <BrowserRouter>
            <LanguageProvider fetchTranslations={fetchTranslations}>
                <ModalProvider>
                    <App/>
                </ModalProvider>
            </LanguageProvider>
        </BrowserRouter>,
        document.getElementById("app")
    );
}

//import Login from "./components/Login/Loginscreen";
const loginscreen = document.querySelectorAll("[loginscreen]");
loginscreen.forEach((el) => {
    ReactDOM.render(
        React.createElement(Login, {
            lang: el.getAttribute("lang"),
            languageitems: el.getAttribute("languagecomponent"),
            loginform: el.getAttribute("loginformAction"),
            sliderdata: el.getAttribute("sliderdata"),
            swissRxPath: el.getAttribute("swissRxPath")
        }),
        el
    );
});