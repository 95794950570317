import React from "react";
import {useModal} from "../libs/ModalProvider";
import Contact from "../views/Modals/Contact";
import {getLanguageContent, TranslateText} from "../libs/LanguageProvider";
import {getLoginStatus, isLoggedIn} from "../helper/User";

export const staticLinks = [

    // menu bar
    {
        title: "menu-title-offers",
        to: "/marketplace/home",
        onMobile: true,
        inMenuBar: true,
        inTopBar: false,
        footer: false,
        content: null,
        backendAccessRequired: false,
        userLevelRequired: 3,
        loginRequired: true,
        modal: null,
        external: false,
    },
    {
        title: "menu-title-pos",
        to: "/marketplace/pos",
        onMobile: true,
        inMenuBar: true,
        inTopBar: false,
        footer: false,
        content: null,
        backendAccessRequired: false,
        userLevelRequired: 3,
        loginRequired: true,
        modal: null,
        external: false,
    },
    {
        title: "menu-title-orders",
        to: "/marketplace/orders",
        onMobile: true,
        inMenuBar: true,
        inTopBar: false,
        footer: false,
        content: null,
        backendAccessRequired: false,
        userLevelRequired: 3,
        loginRequired: true,
        modal: null,
        external: false,
    },
    {
        title: "menu-title-contact",
        to: "/contact",
        onMobile: false,
        inMenuBar: true,
        inTopBar: false,
        footer: false,
        content: null,
        backendAccessRequired: false,
        userLevelRequired: 3,
        loginRequired: true,
        modal: true,
        external: false,
    },

    // icons
    {
        title: "menu-title-university",
        to: "/university/home",
        onMobile: true,
        inMenuBar: false,
        inTopBar: true,
        footer: false,
        content: (
            <div
                className='flex border-solid border-2 border-sanofi-button-default rounded-lg p-1 color-sanofi-lila'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"/>
                </svg>
                <div className='ml-2'>University</div>
            </div>
        ),
        backendAccessRequired: false,
        userLevelRequired: 4,
        loginRequired: false,
        modal: null,
        asButton: true,
        external: true,
        forceMobile: true,
    },
    {
        title: "menu-title-admin",
        to: "/dashboard",
        onMobile: true,
        inMenuBar: false,
        inTopBar: true,
        footer: false,
        content: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"/>
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
            </svg>
        ),
        backendAccessRequired: true,
        userLevelRequired: 2,
        loginRequired: true,
        modal: null,
        external: true,
    },


    {
        title: "menu-title-profile",
        to: "#",
        onMobile: true,
        inMenuBar: false,
        inTopBar: true,
        footer: false,
        content: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
            </svg>
        ),
        backendAccessRequired: false,
        userLevelRequired: 4,
        loginRequired: true,
        modal: null,
        external: false,
        children: [

            {
                title: "menu-title-profile",
                to: "/profile",
                onMobile: true,
                inMenuBar: false,
                inTopBar: true,
                footer: false,
                backendAccessRequired: false,
                userLevelRequired: 4,
                loginRequired: true,
                modal: null,
                external: false,
            },
            {
                title: "menu-title-my-content",
                to: "/mycontent",
                onMobile: true,
                inMenuBar: false,
                inTopBar: true,
                footer: false,
                backendAccessRequired: false,
                userLevelRequired: 4,
                loginRequired: true,
                modal: null,
                external: false,
            },

        ],
    },

    {
        title: "menu-title-logout",
        to: "/logout",
        onMobile: true,
        inMenuBar: false,
        inTopBar: true,
        footer: false,
        content: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"/>
            </svg>
        ),
        backendAccessRequired: false,
        loginRequired: true,
        modal: null,
        external: true,
    },

    // footer
    {
        title: "menu-footer-contact",
        to: "/contact",
        onMobile: false,
        inMenuBar: false,
        inTopBar: false,
        footer: true,
        content: null,
        backendAccessRequired: false,
        userLevelRequired: 3,
        loginRequired: true,
        modal: true
    },
    {
        title: "menu-footer-imprint",
        to: "link-href-nutzungsbedingungen",
        onMobile: false,
        inMenuBar: false,
        inTopBar: false,
        footer: true,
        content: null,
        backendAccessRequired: false,
        modal: false,
        external: true,
        translateLink: true,
    },
    {
        title: "menu-footer-dataPrivacy",
        to: "link-href-dataPrivacy",
        onMobile: false,
        inMenuBar: false,
        inTopBar: false,
        footer: true,
        content: null,
        backendAccessRequired: false,
        modal: false,
        external: true,
        translateLink: true,
    },

]

// FOR UNIVERSITY FRONTEND
export const staticLinksUniversity = [

    // menu bar
    {
        title: "menu-university-title-home",
        defaultTitle: 'Home',
        to: "/university/home",
        onMobile: true,
        inMenuBar: true,
        inTopBar: false,
        footer: false,
        content: null,
        backendAccessRequired: false,
        modal: null,
        external: false,
    },
    {
        title: "menu-university-title-certificates",
        defaultTitle: 'Akkreditierte Fortbildungen',
        to: "/university/elearnings",
        onMobile: true,
        inMenuBar: true,
        inTopBar: false,
        footer: false,
        content: null,
        backendAccessRequired: false,
        modal: null,
        external: false,
    },
    {
        title: "menu-university-title-furtherEducation",
        defaultTitle: 'Produkt-Fortbildungen',
        to: "/university/producttrainings",
        onMobile: true,
        inMenuBar: true,
        inTopBar: false,
        footer: false,
        content: null,
        backendAccessRequired: false,
        modal: null,
        external: false,
    },
    {
        title: "menu-university-title-livewebinar",
        defaultTitle: 'Webinar-Registrierung',
        to: "/university/webinars",
        onMobile: true,
        inMenuBar: true,
        inTopBar: false,
        footer: false,
        content: null,
        backendAccessRequired: false,
        modal: null,
        external: false,
    },

    // icons
    {
        title: "menu-title-marketplace",
        to: "/marketplace/home",
        onMobile: true,
        inMenuBar: false,
        inTopBar: true,
        footer: false,
        content: (
            <div
                className='flex border-solid border-2 border-sanofi-button-default rounded-lg p-1 color-sanofi-lila'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                     stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"/>
                </svg>
                <div className='ml-2'>Marketplace</div>
            </div>
        ),
        backendAccessRequired: false,
        userLevelRequired: 3,
        loginRequired: false,
        modal: null,
        asButton: true,
        external: false,
        forceMobile: true,
    },
    {
        title: "menu-title-admin",
        to: "/dashboard",
        onMobile: true,
        inMenuBar: false,
        inTopBar: true,
        footer: false,
        content: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"/>
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
            </svg>
        ),
        backendAccessRequired: true,
        userLevelRequired: 2,
        loginRequired: true,
        modal: null,
        external: true,
    },

    {
        title: "menu-title-profile",
        to: "#",
        onMobile: true,
        inMenuBar: false,
        inTopBar: true,
        footer: false,
        content: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
            </svg>
        ),
        backendAccessRequired: false,
        userLevelRequired: 4,
        loginRequired: true,
        modal: null,
        external: false,
        children: [

            {
                title: "menu-title-profile",
                to: "/profile",
                onMobile: true,
                inMenuBar: false,
                inTopBar: true,
                footer: false,
                backendAccessRequired: false,
                userLevelRequired: 4,
                loginRequired: true,
                modal: null,
                external: false,
            },
            {
                title: "menu-title-my-content",
                to: "/mycontent",
                onMobile: true,
                inMenuBar: false,
                inTopBar: true,
                footer: false,
                backendAccessRequired: false,
                userLevelRequired: 4,
                loginRequired: true,
                modal: null,
                external: false,
            },

        ],
    },
    {
        title: getLoginStatus() === true ? "menu-title-logout" : "menu-title-login",
        to: getLoginStatus() === true ? "/logout" : "/login",
        onMobile: true,
        inMenuBar: false,
        inTopBar: true,
        footer: false,
        content: (
            getLoginStatus() === true ?
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"/>
                </svg> :
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"/>
                </svg>
        ),
        backendAccessRequired: false,
        loginRequired: false,
        modal: null,
        external: true,
    },

    // footer
    {
        title: "menu-footer-contact",
        to: "/contact",
        onMobile: false,
        inMenuBar: false,
        inTopBar: false,
        footer: true,
        content: null,
        backendAccessRequired: false,
        userLevelRequired: 4,
        loginRequired: true,
        modal: true
    },
    {
        title: "menu-footer-imprint",
        to: "link-href-nutzungsbedingungen",
        onMobile: false,
        inMenuBar: false,
        inTopBar: false,
        footer: true,
        content: null,
        backendAccessRequired: false,
        modal: false,
        external: true,
        translateLink: true,
    },
    {
        title: "menu-footer-dataPrivacy",
        to: "link-href-dataPrivacy",
        onMobile: false,
        inMenuBar: false,
        inTopBar: false,
        footer: true,
        content: null,
        backendAccessRequired: false,
        modal: false,
        external: true,
        translateLink: true,
    },
]


// FOR SANOFICONNECT FRONTEND
export const staticLinksSanoficonnect = [

    // icons
    {
        title: "menu-title-admin",
        to: "/dashboard",
        onMobile: true,
        inMenuBar: false,
        inTopBar: true,
        footer: false,
        content: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"/>
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
            </svg>
        ),
        backendAccessRequired: true,
        userLevelRequired: 2,
        loginRequired: true,
        modal: null,
        external: true,
    },

    {
        title: "menu-title-profile",
        to: "#",
        onMobile: true,
        inMenuBar: false,
        inTopBar: true,
        footer: false,
        content: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
            </svg>
        ),
        backendAccessRequired: false,
        userLevelRequired: 4,
        loginRequired: true,
        modal: null,
        external: false,
        children: [

            {
                title: "menu-title-profile",
                to: "/profile",
                onMobile: true,
                inMenuBar: false,
                inTopBar: true,
                footer: false,
                backendAccessRequired: false,
                userLevelRequired: 4,
                loginRequired: true,
                modal: null,
                external: false,
            },
            {
                title: "menu-title-my-content",
                to: "/mycontent",
                onMobile: true,
                inMenuBar: false,
                inTopBar: true,
                footer: false,
                backendAccessRequired: false,
                userLevelRequired: 4,
                loginRequired: true,
                modal: null,
                external: false,
            },

        ],
    },

    {
        title: getLoginStatus() === true ? "menu-title-logout" : "menu-title-login",
        to: getLoginStatus() === true ? "/logout" : "/login",
        onMobile: true,
        inMenuBar: false,
        inTopBar: true,
        footer: false,
        content: (
            getLoginStatus() === true ?
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"/>
                </svg> :
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"/>
                </svg>
        ),
        backendAccessRequired: false,
        loginRequired: false,
        modal: null,
        external: true,
    },

    // footer
    {
        title: "menu-footer-contact",
        to: "/contact",
        onMobile: false,
        inMenuBar: false,
        inTopBar: false,
        footer: true,
        content: null,
        backendAccessRequired: false,
        userLevelRequired: 4,
        loginRequired: true,
        modal: true
    },
    {
        title: "menu-footer-imprint",
        to: "link-href-nutzungsbedingungen",
        onMobile: false,
        inMenuBar: false,
        inTopBar: false,
        footer: true,
        content: null,
        backendAccessRequired: false,
        modal: false,
        external: true,
        translateLink: true,
    },
    {
        title: "menu-footer-dataPrivacy",
        to: "link-href-dataPrivacy",
        onMobile: false,
        inMenuBar: false,
        inTopBar: false,
        footer: true,
        content: null,
        backendAccessRequired: false,
        modal: false,
        external: true,
        translateLink: true,
    },
]

