import React, {Fragment, useState, useEffect} from "react";
import DefaultCard from "./components/defaultCard";
import {generateCSSString} from "../../helper/styleHelper";
import BgImageCard from "./components/bgImageCard";
import UniversityCard from "./components/UniversityCards/universityCard";


export default function Card(props) {

    const elem = props.elem;

    const cssValue = ['alignment', 'justifyContent', 'alignItems'];

    const [mobileView, setMobileView] = useState(window.innerWidth > 767? false : true);

    useEffect(() => {

        const handleResize = () => {
            if(window.innerWidth > 767) 
                return setMobileView(false);
            return setMobileView(true);
        }
        
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }

    }, [])

    const getElement = () => {
        switch (props.elem.type) {
            case 'default':
                return <DefaultCard elem={props.elem} mobileView={mobileView} target={props.target}/>
            case 'bgimage':
                return <BgImageCard elem={props.elem} mobileView={mobileView} target={props.target}/>
            case 'university':
                return <UniversityCard elem={props.elem}/>
        }
    }


    return (
        <Fragment>
            {elem.link && (mobileView || !elem.buttonTitle)? 
                <a id={'Card-' + elem.uuid}
                className={generateCSSString(elem, cssValue) + 'h-full'}
                href={elem.link}
                target = {props.target?? elem.target?? '_blank'}
                >
                    {getElement()}
                </a>
            :
                <div id={'Card-' + elem.uuid}
                    className={generateCSSString(elem, cssValue) + 'h-full'}>
                    {getElement()}
                </div>
            }
        </Fragment>
    )
}