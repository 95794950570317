import React, {useEffect, useState, Fragment} from "react";
import Button from "./components/Button";
import Card from "./components/Card";
import Html from "./components/Html";
import Image from "./components/Image"
import Title from "./components/Title";
import Form from "./components/Form";
import Row from "./components/Row";
import Column from "./components/Column";
import Text from "./components/Text";
import UniversityCards from "./components/Card/components/UniversityCards/universityCards";
import {generateCSSString} from "./helper/styleHelper";


export default function CMS(props) {

    const elem = props.elem;
    const cssValue = ['marginTop', 'marginBottom','marginLeft', 'marginRight', 'alignItems', 'justifyContent', 'borderRadius'];

    const width = elem.component === 'column' ? generateCSSString(elem, ['width']) : '';
    const height = elem.component === 'card' ? 'grow' : '';

    const getElement = () => {
        switch (elem.component) {
            case 'button':
                return <Button elem={elem} type={props.type}/>
            case 'card':
                return <Card elem={elem}/>
            case 'title':
                return <Title elem={elem}/>
            case 'text':
                return <Text elem={elem}/>
            case 'image':
                return <Image elem={elem}/>
            case 'html':
                return <Html elem={elem}/>
            case 'form':
                return  <Form elem={elem} parentData={props.parentData} />
            case 'row':
                return <Row elem={elem}/>
            case 'column':
                return <Column elem={elem}/>
            case 'cardlist':
                return <UniversityCards elem={elem}/>
        }
    }


    return (
        <div id={'CMS-' + elem.uuid} className={`${generateCSSString(elem, cssValue)} ${width} ${height}`} >
            {
                getElement()
            }
        </div>
    )
}