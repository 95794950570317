import React, {useState, useEffect} from 'react';
import { generateCSSString } from '../../helper/styleHelper';
import store from "../../../SanoficonnectForm/store/FormStore";
import FormBundle from "../../../SanoficonnectForm";
import { Provider } from "react-redux";


export default function Form(props){

    const elem = props.elem;

    const cssValue = ['paddingTop', 'paddingBottom','paddingLeft', 'paddingRight'];

    const getType = () => {
        if(elem.type == 1) return 'survey'
        if(elem.type == 2) return 'quiz'
            return;
    }

    return(
        <>
        { elem.data.status === 'success' &&
        <div className={generateCSSString(elem, cssValue)}>
            <Provider store={store}>
                <FormBundle formdata={elem.data.data} type={getType()} parentData={props.parentData} />
            </Provider>
        </div>
        }
        </>
    )
}

