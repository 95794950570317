import React from "react";
import {generateCSSString} from "../../helper/styleHelper";
import CMS from "../../index";


export default function Row(props) {

    const elem = props.elem
    const cssValue = ['borderRadius'];

    const cssValueInnerPart = ['gapX', 'gapY', 'justifyContent', 'alignItems', 'paddingTop', 'paddingBottom','paddingLeft', 'paddingRight'];

    return (
        <div id={`Row-${elem.uuid}`} className={'overflow-hidden ' + generateCSSString(elem, cssValue)}
             style={{
                 background: ` url('${elem.image}') no-repeat center, ${elem.backgroundColor}`,
                 backgroundSize: 'cover'
             }}>

            <div className={`flex flex-col md:flex-row w-full ${generateCSSString(elem, cssValueInnerPart)}`}
                style={{
                        background: `${elem.overlay? 'linear-gradient(rgba(34, 0, 76, 0) 0%, rgba(34, 0, 76, 0.3) 100%) 0% 0% no-repeat padding-box padding-box' : 'none'}`,
                        color: elem.fontColor
                    }}>
                {elem.children && elem.children.map((child) => <CMS elem={child}/>)}
            </div>

        </div>


    )
}