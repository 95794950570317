import React, {useEffect, useState, Fragment} from "react";
import {generateCSSString} from "../../helper/styleHelper";
import CMS from "../../index";


export default function Column(props) {

    const elem = props.elem;

    const cssValue = ['backgroundColor', 'borderRadius'];

    const cssValueInnerPart = ['justifyContent', 'alignItems', 'paddingTop', 'paddingBottom','paddingLeft', 'paddingRight'];

    return (
        <div className={`overflow-hidden w-full h-full ${generateCSSString(elem, cssValue)}`} style={{background: ` url('${elem.image}') center center / cover no-repeat, ${elem.backgroundColor}`}}>

            <div className={`w-full h-full flex flex-col ${generateCSSString(elem, cssValueInnerPart)}`}
            style={{
                    background: `${elem.overlay? 'linear-gradient(rgba(34, 0, 76, 0) 0%, rgba(34, 0, 76, 0.3) 100%) 0% 0% no-repeat padding-box padding-box' : 'none'}`,
                    color: elem.fontColor
                }}>

                {elem.children && elem.children.map((child) => <CMS elem={child}/>)}

            </div>

        </div>


    )
}