import React, {useState, useEffect} from "react";
import {generateCSSString} from "../../../helper/styleHelper";
import Button from "../../Button";
import Title from "../../Title";

export default function BgImageCard(props) {

    const elem = props.elem;

    const cssValue = ['height', 'borderRadius', 'textDecoration', 'alignment', 'justifyContent'];

    const cssValueInnerPart = ['marginTop', 'marginRight', 'marginLeft', 'marginBottom', 'paddingTop', 'paddingRight', 'paddingLeft', 'borderRadius'];

    const cssTitle = ['fontSize']


    return (
        <div id={`BgImageCard-${elem.uuid}`} 
            className={`${generateCSSString(elem, cssValue)}`} 
            style={{
                backgroundColor: elem.backgroundColor,
                background: ` url('${elem.image}') no-repeat center`,
                backgroundSize: 'cover'
            }}>
            <div className={`card-shadow flex flex-col w-full h-full ${generateCSSString(elem, cssValueInnerPart)} pb-${elem.paddingTop.slice(3)} md:${elem.paddingBottom}`} 
                style={{
                    background: `${elem.overlay? 'linear-gradient(rgba(34, 0, 76, 0) 0%, rgba(34, 0, 76, 0.3) 100%) 0% 0% no-repeat padding-box padding-box' : 'none'}`,
                    color: elem.fontColor
                }}>

                {/* Title */}
                <div className={`mb-2 ${generateCSSString(elem, cssTitle)}`}>
                    <Title elem={{...elem, 
                        textDecoration: 'font-bold', 
                        paddingTop    : 'pt-0',
                        paddingBottom : 'pb-0',
                        paddingLeft   : 'pl-0',
                        paddingRight  : 'pr-0',}}/>
                </div>
               
                <div className={`${elem.buttonTitle && !props.mobileView? 'md:mb-8' : ''}`} dangerouslySetInnerHTML={{__html: elem.content}}/>

                {!props.mobileView && elem.buttonTitle && 
                <div className='flex justify-center mt-auto'>
                    <Button type='card' 
                        elem={{
                            type: 'Button', 
                            content: elem.buttonTitle, 
                            color: elem.buttonColor, 
                            hoverColor: elem.hoverColor,
                            backgroundColor: elem.buttonBackgroundColor,
                            hoverBackgroundColor: elem.hoverBackgroundColor,
                            borderColor: elem.borderColor,
                            hoverBorderColor: elem.hoverBorderColor,
                            alignment: elem.alignment === 'text-center'? 'justify-center': elem.alignment === 'text-right'? 'justify-end' : 'justify-start', 
                            link: elem.link, target: elem.target}}
                        target={props.target}/>
                </div>}

            </div>
        </div>
    )
}