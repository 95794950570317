import React, {useEffect, useState, Fragment, useCallback} from "react";
import {staticLinks} from "../../data/staticLinks";
import MenuLink from "../Header/Menu/MenuLink";
import {getLanguageContent, Translate, TranslateText} from "../../libs/LanguageProvider";
import {useModal} from "../../libs/ModalProvider";
import Contact from "../../views/Modals/Contact";
import {getSubURL, hasBackendAccess, userLevel} from "../../helper/User";
import {getLinks} from "../../helper/Links";

export default function Footer(porps) {

    const [nowYear, setNowYear] = useState(new Date())
    const {setModal, closeModal} = useModal()

    const onModalClick = (child) => {
        setModal(<div><Contact/></div>);
    }

    /* Keep this - just in case they want the follow option again ;)
     <div className="flex items-center">
                        <div
                            className="text-white text-xl mr-3">{getLanguageContent('text-follow-us', 'Folge uns')}</div>
                        <a href={getLanguageContent('sanofi-link-twitter', 'https://twitter.com/sanofi')}>
                            <img className="h-14" src="/react/frontend/src/components/Footer/icons/Twitter.png"/>
                        </a>
                        <a href={getLanguageContent('sanofi-link-youtube', 'https://www.youtube.com/user/sanofiaventisTVen')}>
                            <img className="h-14" src="/react/frontend/src/components/Footer/icons/Youtube.png"/>
                        </a>
                        <a href={getLanguageContent('sanofi-link-linkedin', 'https://www.linkedin.com/company/sanofi')}>
                            <img className="h-14" src="/react/frontend/src/components/Footer/icons/LinkedIn.png"/>
                        </a>
                        <a href={getLanguageContent('sanofi-link-youtube', 'https://www.instagram.com/sanofi/')}>
                            <img className="h-14" src="/react/frontend/src/components/Footer/icons/Instagram.png"/>
                        </a>
       </div>*/

    return (

        <footer className="bg-sanofi-grey text-center lg:text-left mt-auto pt-10">

            <div className="bg-black">
                <div className="flex flex-col items-center md:flex-row md:justify-between py-6 px-10 ">
                    <div className="mb-3 md:mb-0">
                        <img className="h-6"
                             src="/design/svg/sanofi-logo-2022.svg"
                        />
                    </div>

                </div>

            </div>


            <div className="text-gray-700 p-2 bg-sanofi-gray">
                <div className="flex-none md:flex text-sm">
                    <div className="flex-none md:flex-1 md:w-64 p-2 lg:text-left md:content-center">
                        <div className="flex flex-col sm:flex-row lg:text-left space-x-2">

                            {staticLinks.map((link) => {

                                if (link.userLevelRequired && userLevel() > link.userLevelRequired) {
                                    return null;
                                }

                                if (link.backendAccessRequired && !hasBackendAccess()) {
                                    return null;
                                }

                                let ref = link.to;

                                if (link.translateLink === true) {
                                    ref = TranslateText(ref);
                                }

                                if (link.footer !== true) {
                                    return null;
                                }

                                if (link.modal !== false) {

                                    if (link.modal !== null) {
                                        return (
                                            <a title={TranslateText(link.title)}
                                               onClick={(e) => {
                                                   onModalClick(link.modal)
                                               }}
                                               className={"cursor-pointer md:pl-2"}>
                                                {TranslateText(link.title)}
                                            </a>
                                        )
                                    }

                                }

                                return (
                                    <MenuLink external={link.external} className={"md:pl-2"} defaultStyle={false}
                                              href={ref}>{TranslateText(link.title)}</MenuLink>
                                )
                            })}

                        </div>
                    </div>
                    <div className="flex-none md:flex-1 md:w-64 p-2 text-center md:text-right">
                        <div>© Opella {nowYear.getFullYear()} - All rights reserved {TranslateText('MAT-Footer')}</div>
                    </div>
                </div>
            </div>
        </footer>

    )


}