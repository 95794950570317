// other helper methods

export default class SucHelper {
    // will simply return false if the value is "empty"
    static isNullUndefinedOrEmpty(value) {
        if (
            value !== undefined &&
            value !== "" &&
            value !== "null" &&
            value !== null
        ) {
            return false;
        } else {
            return true;
        }
    }

    // MUTABLE! Will remove an entity by index from an array
    static arrayRemove(array, removeItemIndex) {
        if (!Array.isArray(array)) {
            return array;
        }
        const buff = [...array];
        array.splice(index, 1);
        return buff;
    }

    // well remove a key from an object
    static objectRemove(object, key) {
        if (object[key] === undefined) {
            return object;
        }
        const buff = { ...object };
        delete object[key];
        return buff;
    }

    // wll check of an array (of objects) contains a key with value
    static arrayContainsKeyValue(array, key, value) {
        if (!Array.isArray(array)) {
            return false;
        }
        if (array.length <= 0) {
            return false;
        }

        for (let i = 0; i < array.length; i++) {
            if (SucHelper.deepEqual(array[i][key], value)) {
                return true;
            }
        }

        return false;
    }

    // will check if an array contains an object with the same keys (will only check the defined keys!)
    static arrayContainsObject(array, object) {
        if (!Array.isArray(array)) {
            return false;
        }
        if (array.length <= 0) {
            return false;
        }

        const objectKeys = Object.keys(object);
        if (objectKeys.length <= 0) {
            return false;
        }

        for (let i = 0; i < array.length; i++) {
            let arrayObject = array[i];
            let doContinue = false;
            let compareObject = {};
            for (let x = 0; x < objectKeys.length; x++) {
                if (!arrayObject.hasOwnProperty(objectKeys[x])) {
                    doContinue = true;
                    break;
                }
                compareObject[objectKeys[x]] = arrayObject[objectKeys[x]];
            }
            if (doContinue) {
                continue;
            }
            if (SucHelper.deepEqual(compareObject, object)) {
                return true;
            }
        }

        return false;
    }

    // MUTABLE! Will set a specific key of a array of object to the current index value;
    static arrayUpdateKeyByIndex(array, key) {
        if (!Array.isArray(array)) {
            return array;
        }
        if (array.length <= 0) {
            return array;
        }

        const buff = [...array];
        for (let i = 0; i < buff.length; i++) {
            buff[i][key] = i;
        }
        return buff;
    }

    // MUTABLE! Will sort an array of objects by key
    static arraySortByKey(array, key) {
        const buff = [...array];
        return buff.sort(function(a, b) {
            let x = a[key];
            let y = b[key];
            return x < y ? -1 : x > y ? 1 : 0;
        });
    }

    // will perform a deep comparison against to values
    static deepEqual(a, b) {
        if (a === b) return true;

        if (a && b && typeof a == "object" && typeof b == "object") {
            if (a.constructor !== b.constructor) return false;

            let length, i, keys;
            if (Array.isArray(a)) {
                length = a.length;
                if (length !== b.length) return false;
                for (i = length; i-- !== 0;)
                    if (!equal(a[i], b[i])) return false;
                return true;
            }

            if (a instanceof Map && b instanceof Map) {
                if (a.size !== b.size) return false;
                for (i of a.entries()) if (!b.has(i[0])) return false;
                for (i of a.entries())
                    if (!equal(i[1], b.get(i[0]))) return false;
                return true;
            }

            if (a instanceof Set && b instanceof Set) {
                if (a.size !== b.size) return false;
                for (i of a.entries()) if (!b.has(i[0])) return false;
                return true;
            }

            if (ArrayBuffer.isView(a) && ArrayBuffer.isView(b)) {
                length = a.length;
                if (length !== b.length) return false;
                for (i = length; i-- !== 0;) if (a[i] !== b[i]) return false;
                return true;
            }

            if (a.constructor === RegExp)
                return a.source === b.source && a.flags === b.flags;
            if (a.valueOf !== Object.prototype.valueOf)
                return a.valueOf() === b.valueOf();
            if (a.toString !== Object.prototype.toString)
                return a.toString() === b.toString();

            keys = Object.keys(a);
            length = keys.length;
            if (length !== Object.keys(b).length) return false;

            for (i = length; i-- !== 0;)
                if (!Object.prototype.hasOwnProperty.call(b, keys[i]))
                    return false;

            for (i = length; i-- !== 0;) {
                let key = keys[i];
                if (key === "_owner" && a.$$typeof) {
                    // React-specific: avoid traversing React elements' _owner.
                    //  _owner contains circular references
                    // and is not needed when comparing the actual elements (and not their owners)
                    continue;
                }
                if (!SucHelper.deepEqual(a[key], b[key])) return false;
            }

            return true;
        }

        // true if both NaN, false otherwise
        return a !== a && b !== b;
    }

    static getUserLanguages = () => {
        const [html] = document.getElementsByTagName("body");
        const lang = html.getAttribute("data-language");
        return lang === null ? "de" : lang;
    };

    static findComponentInRegistry = (
        type,
        objectCode,
        asComponent = false
    ) => {
        let comp = null;
        comp = window.sucReactRegistry.find((obj) => {
            return (
                obj.name === type &&
                (obj.module === undefined || obj.module === objectCode)
            );
        });

        if (comp === undefined || comp === null) {
            return undefined;
        }

        if (asComponent) {
            return comp.hasOwnProperty("component") ? comp.component : null;
        } else {
            return comp;
        }
    };

    static deepSearch = (entireObj, keyToFind, valToFind) => {
        let foundObj;
        JSON.stringify(entireObj, (_, nestedValue) => {
            if (nestedValue && nestedValue[keyToFind] === valToFind) {
                foundObj = nestedValue;
            }
            return nestedValue;
        });
        return foundObj;
    }


    static updateObject = (object, keyName, newVal) => {
        const results = {};
        for (var key in object) {
            if (key === keyName) {
                results[key] = newVal;
            } else if (typeof object[key] === "object" && object[key] !== null) {
                results[key] = updateObject(keyName, newVal, object[key]);
            } else {
                results[key] = object[key];
            }
        }
        return results;
    }

    static setActiveMenuItem = (item) => {
        window.MenuStore.dispatch(window.setActiveEntry({ activeEntry: item }))
    }
}

window.SucHelper = SucHelper;


