import TopBar from "../../components/Header/TopBar";
import Hero from "../../components/Header/Hero";
import React, {Fragment, useEffect, useState} from "react";
import ContentArea from "../../components/ContentArea";
import Page from "../../components/Page";
import Footer from "../../components/Footer";
import axios from "axios";
import {getUserLanguages} from "../../helper/User";
import {useParams, useLocation} from "react-router-dom";
import Spinner from "../../components/Spinner";
import CMS from "../../components/CMS";

export default function CMSPage(props) {
    // slug = get data from database with slug as ...
    let {slug} = useParams();
    let {type} = useParams();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true)
    const [pageData, setPageData] = useState(null)

    let params = useParams();

    if (props.forceSlug) {
        slug = props.forceSlug;
    }

    if (props.forceType) {
        type = props.forceType;
    }

    const setMetaData = (elem) => {
        if (elem.metaTitle) {
            var htmlTitle = document.getElementsByTagName('title')
            if (htmlTitle) {
                htmlTitle[0].innerHTML = elem.metaTitle
            }

        }
        if (elem.metaDescription) {
            document
                .getElementsByTagName('meta')
                .namedItem('description')
                .setAttribute('content', elem.metaDescription)
        }
        if (elem.metaKeywords) {
            document
                .getElementsByTagName('meta')
                .namedItem('keywords')
                .setAttribute('content', elem.metaKeywords)
        }

    }

    // get Page Data
    useEffect(() => {

        axios({
            dataType: "json",
            method: "post",
            url: "/module/getPageInFrontend/cmspage",
            data: {
                lang: getUserLanguages(),
                type: type || 'sanoficonnect',
                slug: slug // id oder uuid
            }
        }).then(data => {
            //console.log(data.data.data);
            if (data.data && data.data.status === 'success') {
                let page = data.data.data;

                let type = page.type === "sanoficonnect"? 
                'Sanofi Connect' :
                'Sanofi ' + page.type[0].toUpperCase() + page.type.slice(1);

                document.title = `${page.title? page.title + ' - ' : ''}${type}`; 
                 
                setPageData(page);
                setMetaData(page);
            } else {
                setIsLoading(false)
                 window.location.href = '/404';
            }
            setIsLoading(false)
        });

    }, [location])

    //
    return (
        <Fragment>
            {isLoading ?
                <div className="h-screen flex justify-center items-center"><Spinner/></div>
                :
                <Page>
                    {pageData && pageData.headerType !== 0 &&
                        <TopBar type={pageData.type}/>
                    }

                    {pageData &&
                        <Hero 
                            type={pageData.type} 
                            headerType={pageData.headerType} 
                            heroImage={pageData.headerImg}
                            menuType={pageData.menuType}
                            menuItems={pageData.menu.map(elem => {
                                return {...elem, onMobile: true, inMenuBar: true, modal: null, userLevelRequired: elem.visibility, accessRequired: true}})}
                            />
                    }

                    <ContentArea>
                        {
                            pageData.components && pageData.components.map(elem => {
                                if(elem.component == 'form') {
                                    return <CMS elem={elem} parentData={pageData} />
                                } else {
                                    return <CMS elem={elem} />
                                }
                            }
                            )
                        }
                        {
                            pageData.matNummer && 
                            <div className='text-sm text-right w-full mt-5'>{pageData.matNummer}</div>
                        }
                    </ContentArea>

                    {
                        pageData.showFooter !== 0 &&
                        <Footer/>
                    }

                </Page>}

        </Fragment>
    )
}

