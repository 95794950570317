import React, {Fragment} from "react";
import {generateCSSString} from "../../helper/styleHelper";


export default function Title(props) {

    const elem = props.elem;
    const cssValue = ['paddingTop' , 'paddingBottom', 'paddingRight',  'paddingLeft', 'alignment', 'fontSize','textTransform', 'textDecoration'];

    return (
        <Fragment>
            {elem &&
                <div className={`${generateCSSString(elem, cssValue)}`} style={{color: elem.color, textShadow: elem.boxShadow? `0px 3px 6px ${elem.boxShadowColor}`: 'none'}} dangerouslySetInnerHTML={{__html: elem.title}}>
                </div>
            }
        </Fragment>


    )
}