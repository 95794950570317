import React, {useEffect, useState, Fragment, useCallback} from "react";
import {Routes, Route, Link} from "react-router-dom";
import Footer from "../components/Footer";
import TopBar from "../components/Header/TopBar";
import OfferTable from "../components/OfferTable";
import ContentArea from "../components/ContentArea";
import Page from "../components/Page";
import RebateContainer from "../components/RebateContainer";
import Hero from "../components/Header/Hero";
import Profile from "../views/Profile";
import Offer from "../views/Offer";
import Home from "../views/Home";
import Cart from "../views/Cart";
import ThankYou from "../views/ThankYou";
import PosMaterial from "../views/PosMaterial";
import PosBrand from "../views/PosMaterial/PosBrand";
import Orders from "../views/Orders";

import SanoficonnectForm from "../views/SanoficonnectForm";
import Webinarregistration from "../views/Webinarregistration";
import ELearning from "../views/eLearning";
import Producttraining from "../views/Producttraining";
import UniversityRegistrationSuccessful from "../views/UniversityRegistrationSuccessful";
import UniversityHome from "../views/UniversityHome";
import Survey from "../views/Survey";
import CMSPage from "../views/CMSPage";
import ELearningFail from "../views/eLearning/eLearningFail";
import ELearningSuccess from "../views/eLearning/eLearningSuccess";
import MyContent from "../views/MyContent";


export default function App() {

    useEffect(() => {
        import('tw-elements');
    }, []);

    return (
        <Routes>

            {/* Marketplace */}
            <Route path="/marketplace" element={<Home/>}/>
            <Route exact path="/marketplace/home" element={<Home/>}/>
            <Route exact path="/marketplace/offer/:slug" element={<Offer/>}/>
            <Route exact path="/offer/:slug" element={<Offer/>}/>
            <Route exact path="/marketplace/cart" element={<Cart/>}/>
            <Route exact path="/marketplace/thankyou" element={<ThankYou/>}/>
            <Route exact path="marketplace/pos" element={<PosMaterial/>}/>
            <Route path="/marketplace/pos/:slug" element={<PosBrand/>}/>
            <Route path="/marketplace/orders" element={<Orders/>}/>

            {/* General */}
            <Route exact path="/profile" element={<Profile/>}/>
            <Route exact path="/mycontent" element={<MyContent/>}/>

            {/* University */}

            <Route exact path="/university/home"
                   element={<CMSPage forceType={"university"} forceSlug={"home"}/>}/>
            <Route exact path="/university/webinars"
                   element={<CMSPage forceType={"university"} forceSlug={"webinars"}/>}/>
            <Route exact path="/university/elearnings"
                   element={<CMSPage forceType={"university"} forceSlug={"elearnings"}/>}/>
            <Route exact path="/university/producttrainings"
                   element={<CMSPage forceType={"university"} forceSlug={"producttrainings"}/>}/>

            <Route path="/university/webinars/:slug"
                   element={<Webinarregistration key={document.location.href}/>}/>
            <Route path="/university/elearnings/fail" element={<ELearningFail key={document.location.href}/>}/>
            <Route path="/university/elearnings/success" element={<ELearningSuccess/>}/>
            <Route path="/university/elearnings/:slug" element={<ELearning key={document.location.href}/>}/>

            <Route path="/university/producttrainings/:slug"
                   element={<Producttraining key={document.location.href}/>}/>
            <Route path="/university/surveys/:slug" element={<Survey key={document.location.href}/>}/>
            {/* <Route exact path="/university/home" element={<UniversityHome/>}/> */}
            <Route path="/university/registration-successful" element={<UniversityRegistrationSuccessful/>}/>


            <Route path="/form/:slug" element={<SanoficonnectForm key={document.location.href}/>}/>
            <Route path="/:type/:slug" element={<CMSPage key={document.location.href}/>}/>
            <Route path="/:slug" element={<CMSPage key={document.location.href}/>}/>
        </Routes>
    );
}

