import React from "react";
import UniversityCard from "./universityCard";
import {generateCSSString} from "../../../../helper/styleHelper";
import {getLanguageContent} from "../../../../../../libs/LanguageProvider";
import { useState } from "react";

export default function UniversityCards(props) {

    const step = 6;
    const [limit, setLimit] = useState(step);
    const cssValue = ['paddingTop', 'paddingBottom', 'paddingLeft', 'paddingRight', 'gapX', 'gapY'];

    const showMore = () => {
        setLimit(old => old + step);
    }

    return (
        <>
        {props.elem.data && props.elem.data.length > 0 ?
            <>
            <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ${generateCSSString(props.elem, cssValue)}`}>
                {
                    props.elem.data.slice(0, limit).map(elem =>
                        <UniversityCard elem={elem}/>)
                }
                {props.elem.data.length > limit && <div className="flex justify-center col-span-full">
                    <button className="show-more-button" onClick={showMore}>{getLanguageContent('university-show-more', 'Mehr anzeigen')}</button>
                </div>
                }
            </div>
            </>
        :
            <div className={`w-full text-center ${generateCSSString(props.elem, cssValue)}`}>{getLanguageContent('university-no-entries', 'Keine Einträge gefunden')}</div>
            
        }

        </>

    )
}