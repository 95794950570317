import React, {useEffect, useState, Fragment, useCallback} from "react";
import 'tw-elements';
import Popover from "../../Popover";

import FieldElement from "../../SanoficonnectForm/components/fieldElement";

export default function Radio(props) {

    const myProps = {
        ...props.styles,
        className: (props.overwriteClassName === undefined
            ? ""
            : "") + props.className,
        children: undefined,
    };

    return (

        <div className='flex flex-col md:flex-row flex-wrap no'>
            {
                props.options && props.options.length > 0 && props.options.map(option => {
                        return <div className={props.optionWidth + ' flex'}>
                            <label className='flex'>

                                <input
                                    className='shrink-0 form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-lg bg-white checked:bg-sanofi-dark-lila checked:border-sanofi-dark-lila focus:outline-none transition duration-200 mt-1 ml-1 mr-1 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer'
                                    type="radio"
                                    value={option.value}
                                    checked={props.value[option.uuid] === option.value}
                                    onClick={(e) => props.onValueChange(e, option.uuid)}
                                    required={props.required}
                                    name={props.name || "radio"}
                                />

                                {option.value} {option.info && <Popover info={option.info}/>}

                            </label>
                        </div>
                    }
                )
            }
        </div>
    )


}