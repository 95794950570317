/**
 * Generate the string with the CSS elements
 * @param elem object - with the css values
 * @param keys array - keys that should get included in css
 */
export const generateCSSString = (elem, keys) => {
    let cssString = ""
    keys.forEach((key) => {
        if (elem[key]) {
            cssString += elem[key] + ' '
        }
    })
    return cssString
}