import React, {useEffect, useState, Fragment} from "react";
import axios from "axios";
import InfoTile from "../InfoTile";
import {getLanguageContent} from "../../libs/LanguageProvider";
import WebinarAgenda from "./components/Agenda";
import {Provider} from "react-redux";
import store from "../SanoficonnectForm/store/FormStore";
import FormBundle from "../SanoficonnectForm";
import {getUserLanguages} from "../../helper/User";
import Spinner from "../Spinner";

// Create Webinarregistration Screen
export default function WebinarregistrationScreen(props) {
    const [hasLoaded, setHasLoaded] = useState(false)
    const [webinarData, setwebinarData] = useState({})
    const [webinarAgenda, setWebinarAgenda] = useState({})
    // const [webinarForm, setWebinarForm] = useState({})


    useEffect(() => {
        axios({
            dataType: "json",
            method: "post",
            url: "/module/getWebinar/webinar",
            data: {
                lang: getUserLanguages(),
                uuid: props.slug // uuid
            }
        }).then(data => {
            if (data.data && data.data.status === 'success') {
                setwebinarData(data.data.data)
                //setWebinarAgenda(data.data.data.agenda)
                sortAgendaByDay(data.data.data.agenda)
                setHasLoaded(true);
            } else {
                window.location.href = '/404';
            }
        });

    }, [])

    const sortAgendaByDay = (agenda) => {
        const output = {};

        for (const entry of agenda) {
            const {agenda_date} = entry;
            if (!(agenda_date in output)) {
                output[agenda_date] = [];
            }
            output[agenda_date].push(entry);
        }
        setWebinarAgenda(output)
    }


    const getTimeString = () => {
        if (webinarData['time_date'] && webinarData['time_from'] && webinarData['time_to']) {
            const splittedFrom = webinarData['time_from'].split(':');
            const splittedTo = webinarData['time_to'].split(':');

            if (splittedFrom.length === 3 && splittedTo.length === 3) {
                return getLanguageContent('text-at', 'Am') + ' ' + webinarData['time_date'] + ' ' + splittedFrom[0] + ':' + splittedFrom[1] + ' ' +
                    getLanguageContent('text-to', 'bis') + ' ' + splittedTo[0] + ':' + splittedTo[1] + ' ' + getLanguageContent('text-oClock', 'Uhr ')
            }
            return getLanguageContent('text-at', 'Am') + ' ' + webinarData['time_date'] + ' ' + webinarData['time_from'] + ' ' +
                getLanguageContent('text-to', 'bis') + ' ' + webinarData['time_to'] + ' ' + getLanguageContent('text-oClock', 'Uhr ')
        }

    }

    if (!hasLoaded) {
        return <Spinner/>;
    }
    return (
        <Fragment>
            <h2 className="text-xl uppercase font-600 mb-5">{getLanguageContent('sub-sub-title', 'Webinar Registrierung')}</h2>
            <h1 className="text-3xl color-sanofi-dark-lila font-bold mb-10">{webinarData.title}</h1>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 mb-10">
                {getTimeString() &&
                    <InfoTile className="" rowCount="4" iconType='time' title={getLanguageContent('text-when', 'Wann')}
                              description={getTimeString()}/>}
                {webinarData.place &&
                    <InfoTile className="" rowCount="4" iconType='place' title={getLanguageContent('text-where', 'Wo')}
                              description={webinarData.place}/>}
                {webinarData.speaker && <InfoTile className="" rowCount="4" iconType='referent'
                                                  title={getLanguageContent('text-referent', 'Referent')}
                                                  speaker={webinarData.speaker}/>}
                {webinarData.accreditation && <InfoTile className="" rowCount="4" iconType='certificate'
                                                        title={getLanguageContent('text-accreditation', 'Akkreditierung')}
                                                        accreditation={webinarData.accreditation}/>}
            </div>

            {webinarData.description && <div className="mb-10" dangerouslySetInnerHTML={{__html: webinarData.description}}></div>}

            <div className="rounded-md bg-sanofi-dark-lila py-5 px-10 mb-10">
                <h3 className="avgDemi text-4xl font-bold mb-5 text-white mb-5">{getLanguageContent('webinarregistration-agenda', 'Agenda')}</h3>
                <WebinarAgenda agendaitems={webinarAgenda}/>
            </div>

            {webinarData.formData.status === 'success' &&
                <Provider store={store}>
                    <FormBundle formdata={webinarData.formData.data} share={true} parentData={webinarData} />
                </Provider>
            }

            { webinarData.matnumber && <div className="mt-10 text-sm">{webinarData.matnumber}</div> }

        </Fragment>
    )
}
