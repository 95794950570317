import React, { useEffect, useState, Fragment, useCallback, useContext } from "react";
import axios from "axios";
import SanofiButton from "../../../components/SanofiButton";
import { TranslateText } from "../../../libs/LanguageProvider";
import IconStar from "../../../components/Rating/components/IconStar";
import { getUserLanguages } from "../../../helper/User";
import { Dialog, Transition } from "@headlessui/react";


export default function RatingModal({
    show,
    module,
    entityUuid,
    title = 'raiting-training',
    scale = [1, 2, 3, 4, 5],
}){

    const [isActive, setIsActive] = useState(false);
    const [ratingValue, setRatingValue] = useState(0);


    const closeModal = () => {
        axios({
            dataType: "json",
            method: "post",
            url: "/module/submitRating/rating",
            data: {
                lang: getUserLanguages(),
                module: module,
                entityUuid: entityUuid,
                rating: ratingValue,
            }
        }).then(data => {

            if (data.data.status && data.data.status === "error") {
                alert(data.data.message);
                setErrorState(true);
                return;
            }
            setIsActive(false);
        });
    }

    useEffect(() => axios({
        dataType: "json",
        method: "post",
        url: "/module/getUserEntityRating/rating",
        data: {
            lang: getUserLanguages(),
            module: module,
            entityUuid: entityUuid
        }
    }).then(data => {

        if (data.data.status && data.data.status === "error") {
            alert(data.data.message);
            setErrorState(true);
            return;
        }

        if (data.data.data.length === 0) {
            setIsActive(true);
        }
    }), []);

    useEffect(() => {
        const bind = e => {
            if (e.keyCode !== 27) {
                return
            }

            if (document.activeElement && ['INPUT', 'SELECT'].includes(document.activeElement.tagName)) {
                return
            }

            closeModal()
        }

        document.addEventListener('keyup', bind)
        return () => document.removeEventListener('keyup', bind)
    }, [closeModal])

    return (
            <Transition appear show={show && isActive} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-screen-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div className={"flex items-center justify-center flex-col "}>
                                        <div className={"mt-8 text-center w-128"}>
                                            <h3 className={"text-2xl color-sanofi-dark-lila mt-8"}>{TranslateText(title)}</h3>
                                            <div className={"mt-10 mb-10 text-lg flex justify-center gap-3"}>
                                                {
                                                    scale.map((number) =>
                                                        <div className="cursor-pointer" onClick={() => setRatingValue(number)}><IconStar number={number} filled={ratingValue >= number} /></div>
                                                    )
                                                }
                                            </div>
                                            <dic className={"pt-4 mt-8"}>
                                                <SanofiButton onClick={(e) => { closeModal() }}>
                                                    {TranslateText("rating-popup-close")}
                                                </SanofiButton>
                                            </dic>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
    )
}