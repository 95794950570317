export const hasBackendAccess = () => {
    const selector = document.querySelector("#app");
    if (selector !== null) {
        return selector.getAttribute("backendaccess") === "1";
    }
    return false;
}

export const userLevel = () => {
    const selector = document.querySelector("#app");
    if (selector !== null) {
        return selector.getAttribute("ulr") || "5";
    }
    return false;
}

export const getUserLanguages = () => {
    const [html] = document.getElementsByTagName("html")
    const lang = html.getAttribute("lang");
    return (lang === null ? "de" : lang);
}

export const isLoggedIn = () => {
    const selector = document.querySelector("#app");
    if (selector !== null) {
        return selector.getAttribute("loggedin") === "1";
    }
    return false;
}

export const getLoginStatus = () => {
    //console.log(userLevel(), 5, parseInt(userLevel()) < 5)
    return parseInt(userLevel()) < 5;
}

export const getSubURL = () => {
    const selector = document.querySelector("#app");
    if (selector !== null) {
        return selector.getAttribute("place");
    }
    return 'sanoficonnect';
}

/*export const getStaticLinks = () => {
    switch(getSubURL()){
        case 'marketplace': return staticLinks
        case 'university': return staticLinksUniversity
        default: return staticLinks;
    }
}*/