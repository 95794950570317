import React, {useEffect, useRef} from "react";
import {generateCSSString} from "../../helper/styleHelper";

export default function Html(props){

    const divRef = useRef(null)

    const elem = props.elem;
    
    const internCssValue = ['width'];
    const cssValue = ['paddingTop', 'paddingBottom','paddingRight', 'paddingLeft'];

    useEffect(() => {
        const content = document.createRange().createContextualFragment(elem.content) 
        divRef.current.innerHTML = ''
        divRef.current.appendChild(content) 
      }, [elem.content])

    return(
        <div className={`flex  ${generateCSSString(elem, cssValue)} w-full`}>
            <div ref={divRef} className={`${generateCSSString(elem, internCssValue)}`}>
            </div>
        </div>
    )
}