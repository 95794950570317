import * as React from "react";

export default function IconStar({
    number,
    filled,
    width = "52",
    height = "52"
}) {

    let color = filled ? "rgb(122, 0, 230)" : "rgb(34,0,76)";

    return (
        <svg width={height} height={width} viewBox="0 0 60 57" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/"
            style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                strokeLinecap: "round",
                strokeLinejoin: "round"
            }}>
            <g transform="matrix(1,0,0,1,-352.034,8.37819e-05)">
                <g transform="matrix(1,0,0,1,-794.296,-4765.5)">
                    <g id="Pfad_868-5" transform="matrix(1,0,0,1,1144.98,4763.85)">
                        <path d="M29.52,4.228C29.787,3.577 30.423,3.15 31.127,3.15C31.83,3.15 32.466,3.577 32.733,4.228L39.3,20.016C39.55,20.617 40.118,21.03 40.767,21.082L57.81,22.447C58.708,22.519 59.408,23.279 59.408,24.179C59.408,24.687 59.186,25.17 58.8,25.5L45.815,36.627C45.321,37.05 45.104,37.715 45.253,38.348L49.223,54.983C49.255,55.115 49.271,55.251 49.271,55.387C49.271,56.339 48.487,57.123 47.535,57.123C47.215,57.123 46.901,57.034 46.628,56.867L32.028,47.955C31.473,47.617 30.773,47.617 30.218,47.955L15.618,56.87C15.345,57.037 15.031,57.126 14.711,57.126C13.759,57.126 12.975,56.342 12.975,55.39C12.975,55.254 12.991,55.118 13.023,54.986L17,38.347C17.15,37.714 16.933,37.048 16.438,36.626L3.45,25.5C3.066,25.17 2.845,24.688 2.845,24.181C2.845,23.281 3.544,22.521 4.442,22.448L21.488,21.083C22.137,21.031 22.705,20.618 22.955,20.017L29.52,4.231L29.52,4.228Z"
                            style={{
                                fill: "none",
                                fillRule: "nonzero",
                                stroke: color,
                                strokeWidth: "3px"
                            }} />
                    </g>
                </g>
                <g transform="matrix(1,0,0,1,-14305.5,-3994.34)">
                <g transform="matrix(1,0,0,1,14681.9,4031.75)">
                        <text x="0px" y="0px" style={{
                            fontFamily: "Arial-BoldMT, Arial , sans-serif",
                            fontWeight: "700",
                            fontSize: "19px",
                            fill: color,
                        }}>{number}</text>
                    </g>
                </g>
            </g>
        </svg>
    );
}




