import React from "react";
import {generateCSSString} from "../../helper/styleHelper";

export default function Image(props){

    const elem = props.elem;
    
    const internCssValue = ['width'];
    const cssValue = ['paddingTop', 'paddingBottom','paddingRight', 'paddingLeft', 'alignment'];

    return(
        <div  className={`flex  ${generateCSSString(elem, cssValue)} w-full`}>
            {elem.link?
                <a href={elem.link} target={props.target?? elem.target?? '_blank'}  className={`${generateCSSString(elem, internCssValue)}`}>
                    <img src={elem.image} className={`w-full h-auto`} />
                </a>
                :
                <img src={elem.image} className={`${generateCSSString(elem, internCssValue)} h-auto`}/>
            }

        </div>
    )
}