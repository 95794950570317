import React, {useEffect, useState, Fragment, useCallback} from "react";
import {Link, NavLink} from "react-router-dom";
import MenuLink from "../Menu/MenuLink";
import {getLanguageContent, TranslateText} from "../../../libs/LanguageProvider";
import {getLoginStatus, getSubURL, hasBackendAccess, userLevel} from "../../../helper/User";
import {getLinks} from "../../../helper/Links";
import {staticLinks} from "../../../data/staticLinks";
import {useModal} from "../../../libs/ModalProvider";
import Contact from "../../../views/Modals/Contact";
import MenuDropDown from "../Menu/MenuDropDown";

export default function TopBar(props) {

    const [state, setState] = useState(false)
    const {setModal, closeModal} = useModal()

    const pagetype = props.type ? props.type : getSubURL();

    const onModalClick = (child) => {
        setModal(<div><Contact/></div>);
    }

    const getLogo = () => {
        switch (pagetype) {
            case 'marketplace':
                return "/design/frontend/img/mp-logo.png";
            case 'university':
                return "/design/frontend/img/university_logo.png";
            case 'sanoficonnect':
                return "/design/frontend/img/sanoficonnect_logo.png";
            default:
                return "/design/frontend/img/mp-logo.png"

        }
    }

    const getLogoLink = () => {
        switch (pagetype) {
            case 'marketplace':
                return "/marketplace/home";
            case 'university':
                return "/university/home";
            case 'sanoficonnect':
                return "/home";
            default:
                return "/marketplace/home"

        }
    }

    //Icons by https://heroicons.com/
    return (

        <nav className="bg-white w-full border-b md:border-0 sticky top-0 z-20">
            <div className="relative items-center justify-between px-2 md:flex md:px-8">
                <div className="flex items-center justify-between py-3 md:py-3 md:block">
                    <a href={getLogoLink()}>
                        <img className="h-12"
                             src={getLogo()}
                             alt={pagetype}
                        />
                    </a>
                    <div className="md:hidden">
                        <button className="text-gray-700 outline-none p-2 rounded-md focus:border-gray-400 focus:border"
                                onClick={() => setState(!state)}
                        >
                            {
                                state ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20"
                                         fill="currentColor">
                                        <path fillRule="evenodd"
                                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                              clipRule="evenodd"/>
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                              d="M4 8h16M4 16h16"/>
                                    </svg>
                                )
                            }
                        </button>
                    </div>
                </div>
                <div
                    className={`justify-self-center pb-3 mt-2 md:hidden md:pb-0 md:mt-0 ${state ? 'block' : 'hidden'}`}>
                    <ul className="justify-center items-center md:flex md:space-x-6 md:space-y-0 p-0">

                        {getLinks(props.type, props.menueItems).map((link, idx) => {

                            if (!link.onMobile) {
                                return null
                            }

                            if (link.loginRequired && !getLoginStatus()) {
                                return null;
                            }

                            if (link.userLevelRequired && userLevel() > link.userLevelRequired) {
                                return null;
                            }

                            if (link.backendAccessRequired) {
                                if (!hasBackendAccess()) {
                                    return null
                                }
                            }

                            if (link.content && link.forceMobile) {
                                return (<div key={idx}>
                                    <MenuLink href={link.to} external={link.external}
                                              title={props.isCustom ? link.title : getLanguageContent(link.title, link.defaultTitle)}
                                              defaultStyle={false}
                                              activeClass={"block p-4 bg-sanofi-gray text-sanofi-dark-lila hover:bg-sanofi-gray-darker"}
                                              inactiveClass={"block p-4 text-gray-600 hover:bg-sanofi-gray"}>
                                        {link.content}
                                    </MenuLink>
                                </div>)
                            }

                            if (link.children && Array.isArray(link.children)) {
                                let elements = [];
                                for (let child of link.children) {

                                    if (!child.onMobile) {
                                        return null
                                    }

                                    if (child.loginRequired && !getLoginStatus()) {
                                        return null;
                                    }

                                    if (child.userLevelRequired && userLevel() > child.userLevelRequired) {
                                        return null;
                                    }

                                    if (child.backendAccessRequired) {
                                        if (!hasBackendAccess()) {
                                            return null
                                        }
                                    }

                                    elements.push(
                                        <div key={idx}>
                                            <MenuLink href={child.to} external={child.external}
                                                      title={props.isCustom ? child.title : getLanguageContent(child.title, child.defaultTitle)}
                                                      defaultStyle={false}
                                                      activeClass={"block p-4 bg-sanofi-gray text-sanofi-dark-lila hover:bg-sanofi-gray-darker"}
                                                      inactiveClass={"block p-4 text-gray-600 hover:bg-sanofi-gray"}>
                                                {props.isCustom ? child.title : getLanguageContent(child.title, child.defaultTitle)}
                                            </MenuLink>
                                        </div>
                                    )
                                }
                                return elements;

                            }
                            return (
                                <div key={idx}>
                                    <MenuLink href={link.to} external={link.external}
                                              title={props.isCustom ? link.title : getLanguageContent(link.title, link.defaultTitle)}
                                              defaultStyle={false}
                                              activeClass={"block p-4 bg-sanofi-gray text-sanofi-dark-lila hover:bg-sanofi-gray-darker"}
                                              inactiveClass={"block p-4 text-gray-600 hover:bg-sanofi-gray"}>
                                        {props.isCustom ? link.title : getLanguageContent(link.title, link.defaultTitle)}
                                    </MenuLink>
                                </div>
                            )


                        })}

                    </ul>
                </div>
                <div className="hidden md:inline-block">

                    <div className="flex items-center justify-between space-x-5 py-3">

                        {getLinks(props.type, props.menueItems).map((link, idx) => {

                            if (!link.inTopBar) {
                                return null
                            }

                            if (link.userLevelRequired && userLevel() > link.userLevelRequired) {
                                return null;
                            }


                            if (link.backendAccessRequired) {
                                if (!hasBackendAccess()) {
                                    return null
                                }
                            }

                            if (link.asButton) {
                                return (
                                    <MenuLink
                                        href={link.to}
                                        external={link.external}
                                        defaultStyle={false}
                                        children={link.content}
                                    />
                                )
                            }

                            if (link.children && Array.isArray(link.children)) {
                                return <MenuDropDown href={link.to} external={link.external}
                                                     title={props.isCustom ? link.title : TranslateText(link.title)}
                                                     linkChildren={link.children}>
                                    {link.content}
                                </MenuDropDown>

                            }

                            if (link.modal !== null) {
                                return (
                                    <a title={props.isCustom ? link.title : TranslateText(link.title)}
                                       onClick={(e) => {
                                           onModalClick(link.modal)
                                       }}
                                       className={"cursor-pointer"}>
                                        {props.isCustom ? link.title : TranslateText(link.title)}
                                    </a>
                                )
                            }

                            return (
                                <a className='flex' href={link.to}>
                                    <MenuLink href={link.to} external={link.external}
                                              title={props.isCustom ? link.title : TranslateText(link.title)}
                                              defaultStyle={false}
                                              activeClass={"bg-sanofi-gray rounded-2xl hover:bg-sanofi-gray-darker "}>
                                        {link.content}
                                    </MenuLink>
                                    <span
                                        className='ml-0.5'>{props.isCustom ? link.title : TranslateText(link.title)}</span>
                                </a>
                            )

                        })}
                    </div>
                </div>
            </div>
        </nav>
    )


}