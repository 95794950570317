import React, { Fragment, useEffect, useState } from "react";
import { getLanguageContent, TranslateText } from "../../libs/LanguageProvider";
import Spinner from "../Spinner";
import InfoTile from "../InfoTile";
import Schedule from "./component/Schedule";
import ProducttrainingCard from "./component/ProducttrainingCard";
import SanofiButtonDark from "../SanofiButtonDark";
import axios from "axios";
import { getUserLanguages } from "../../helper/User";
import { getLanguageAsString } from "../../helper/Language";
import RatingModal from "../../views/Modals/Rating";
import "./style/Producttraining.css"


// PATH => /university/producttrainings/{slug}
export default function ProducttrainingComponent(props) {
    const [hasLoaded, setHasLoaded] = useState(false)
    const [producttrainingData, setProducttrainingData] = useState({})
    const [completeDescription, setCompleteDescription] = useState(false)
    const [hasVideoEnded, setHasVideoEnded] = useState(false)
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        axios({
            dataType: "json",
            method: "post",
            url: "/module/getProducttraining/producttraining",
            data: {
                lang: getUserLanguages(),
                uuid: props.slug // uuid
            }
        }).then(data => {
            if (data.data && data.data.status === 'success') {
                setProducttrainingData(data.data.data);
                setHasLoaded(true)
            } else {
                window.location.href = '/404';
            }
        });


    }, [])

    const getDuration = (durationAsString) => {
        const [hours, minutes, seconds] = durationAsString.split(':');
        let output = '';
        if (Number(hours) > 0) {
            if (Number(hours) > 1) {
                output += Number(hours) + ' ' + getLanguageContent('hours', 'Stunden');
            } else {
                output += Number(hours) + ' ' + getLanguageContent('hour', 'Stunde');
            }
        }
        if (Number(minutes) > 0) {
            if (Number(hours) > 0) {
                output += ' ' + getLanguageContent('and', 'und') + ' ';
            }
            if (Number(minutes) > 1) {
                output += Number(minutes) + ' ' + getLanguageContent('minutes', 'Minuten');
            } else {
                output += Number(minutes) + ' ' + getLanguageContent('minute', 'Minute');
            }
        }

        return output;
    }

    const generateCertificate = () => {
        axios({
            dataType: "json",
            method: "post",
            url: "/module/getCertificate/certificate",
            data: {
                type: "producttraining",
                uuid: producttrainingData.uuid, // uuid
                lang: getLanguageAsString(producttrainingData.lang)
            }
        }).then(data => {
            if (data.data && data.data.status === 'success') {
                const aEl = document.createElement('a');
                aEl.href = data.data.data.file;
                aEl.target = '_blank';
                aEl.download = 'certificate';
                aEl.click();
            } else {
                if (data.data.message) {
                    alert(data.data.message)
                } else {
                    alert('Error while generating the certificate - please try again')
                }
            }
        });
    }

    const onVideoEnded = () => {
        setHasVideoEnded(true);
        setShowModal(true);
    }

    if (!hasLoaded) {
        return <Spinner />;
    }

    return (
        <Fragment>

            <RatingModal  show={showModal} module='producttraining' entityUuid={props.slug}/>

            <div className="md:grid md:grid-cols-4">
                <div className="md:col-span-3 md:pr-5">
                    <h2 className="text-xl uppercase md:font-600 mb-5">{getLanguageContent('sub-sub-title-producttraining', 'Produkttraining')}</h2>
                    <h1 className="text-3xl color-sanofi-dark-lila font-bold mb-5">{producttrainingData.title}</h1>

                    <div
                        className='text-lg mb-3'>{getLanguageContent('duration', 'Dauer')}: {getDuration(producttrainingData.duration)}
                    </div>

                    {completeDescription ?
                        producttrainingData.description && <div className="md:hidden" dangerouslySetInnerHTML={{ __html: producttrainingData.description }}></div>
                        :
                        producttrainingData.description && <div className="container-description md:hidden">
                            <div className="ellipsis" dangerouslySetInnerHTML={{ __html: producttrainingData.description }}></div>
                            <button className="underline underline-offset-1"
                                onClick={() => setCompleteDescription(true)}>{getLanguageContent('text-more', 'Mehr')}</button>
                        </div>
                    }

                    {producttrainingData.description && <div className="hidden md:block" dangerouslySetInnerHTML={{ __html: producttrainingData.description }}></div>}

                </div>

                <div className='flex md:flex-col justify-start gap-x-5 md:bg-sanofi-gray rounded-md mt-5 md:mt-0'>
                    {producttrainingData.therapyarea && <InfoTile className="" rowCount="4" iconType='therapyarea' title={getLanguageContent('text-therapyarea', 'Therapiegebiet')} description={producttrainingData.therapyarea} />}
                </div>

            </div>

            <div className="my-5">
                {producttrainingData.hasCertificate == 1 && <Schedule />}
            </div>

            <ProducttrainingCard title={getLanguageContent('text-see-producttraining', 'Training anschauen')}
                counter="1"
                open={true}
                disabled={false}
                svg={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke-width="1.5" stroke="currentColor"
                    className="w-6 h-6 color-sanofi-dark-lila mt-1">
                    <path stroke-linecap="round"
                        d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                </svg>}>

                <div>
                    <video
                        id="my-player"
                        className="video-js"
                        controls
                        preload="auto"
                        data-setup='{}'
                        onEnded={onVideoEnded}>
                        <source
                            src={producttrainingData.video}
                            type="video/mp4" />
                        <source
                            src={producttrainingData.video}
                            type="video/x-msvideo" />
                        <p className="vjs-no-js">
                            To view this video please enable JavaScript, and consider upgrading to a
                            web browser that
                            <a href="http://videojs.com/html5-video-support/" target="_blank">
                                supports HTML5 video
                            </a>
                        </p>
                    </video>
                </div>

                {producttrainingData.hasCertificate == 1 && hasVideoEnded && <div>
                    <div className="md:text-right text-center mt-8">
                        <SanofiButtonDark type="button" onClick={generateCertificate}>
                            {getLanguageContent('text-producttraining-see-certificate', 'Zertifikat downloaden')}
                        </SanofiButtonDark>
                    </div>
                </div>
                }
            </ProducttrainingCard>

            {producttrainingData.matnumber && <div className="mt-10 text-sm">{producttrainingData.matnumber}</div>}

        </Fragment>
    )
}

