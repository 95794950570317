import React, {Fragment} from "react";
import ContentArea from "../../components/ContentArea";
import Page from "../../components/Page";
import Footer from "../../components/Footer";
import {Provider} from "react-redux";
import FormBundle from "../../components/SanoficonnectForm";
import store from "../../components/SanoficonnectForm/store/Formstore"
import {useParams} from "react-router-dom";
import TopBar from "../../components/Header/TopBar";
import Hero from "../../components/Header/Hero";


export default function Survey(props) {

    let {slug} = useParams();


    return (
        <Fragment>
            <Page>
                <TopBar logo={'Sanofi University'}/>
                <Hero/>
                <ContentArea>
                    <Provider store={store}>
                        <FormBundle slug={slug} type='survey'/>
                    </Provider>
                </ContentArea>
                <Footer/>
            </Page>
        </Fragment>
    )
}

