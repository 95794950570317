import React, {useEffect, useState} from "react";
import SanofiButtonDark from "../../../SanofiButtonDark";
import {generateCSSString} from "../../helper/styleHelper";

export default function Button(props) {

    const elem = props.elem;
    const cssValueButton = ['fontSize', 'marginTop', 'marginBottom', 'marginRight', 'marginLeft','paddingTop', 'paddingBottom', 'paddingRight', 'paddingLeft'];

    const cssValueContainerButton = ['alignment'];

    const [backgroundColor, setbackgroundColor] = useState(elem.backgroundColor);
    const [borderColor, setBorderColor] = useState(elem.borderColor);
    const [color, setColor] = useState(elem.color);

    useEffect(()=> {
        setbackgroundColor(elem.backgroundColor);
        setBorderColor(elem.borderColor);
        setColor(elem.color);
    }, [elem])

    const setHoverCssStyles = () => {
        setbackgroundColor(elem.hoverBackgroundColor);
        setBorderColor(elem.hoverBorderColor);
        setColor(elem.hoverColor);
    }

    const resetrCssStyles = () => {
        setbackgroundColor(elem.backgroundColor);
        setBorderColor(elem.borderColor);
        setColor(elem.color);
    }



    const getButtonStyles = () => {
        switch (props.type) {
            case 'card':
                return ` md:block rounded-3xl px-14 py-3 ${generateCSSString(elem, cssValueButton)}`
            default:
                return ` md:block rounded-3xl w-fit ${generateCSSString(elem, cssValueButton)}`
        }
    }

    const getElement = () => {
        switch (elem.type) {
            case 'Button':
                return (
                    <a className = {`${getButtonStyles()}`}
                        style = {{
                            backgroundColor: backgroundColor,
                            border: `1px solid ${borderColor}`,
                            color: color
                        }}
                        onMouseEnter={() => setHoverCssStyles()}
                        onMouseLeave={() => resetrCssStyles()}

                        href = {props.elem.link}
                        target = {props.target?? elem.target?? '_blank'}
                        onClick={elem.onClick ? elem.onClick : null}
                    >
                        {props.elem.content}

                    </a>)

            case 'Link':
                return (
                    <a
                        className = {"block w-fit " + generateCSSString(elem, cssValueButton)}
                        style = {{color: color}}
                        onMouseEnter={() => setColor(elem.hoverColor)}
                        onMouseLeave={() => setColor(elem.color)}
                        href = {props.elem.link}
                        target = {props.target?? elem.target?? '_blank'}
                    >
                        <u>{props.elem.content}</u>
                    </a>)
        }
    }
    return (
        <div className={'w-full flex ' + generateCSSString(elem, cssValueContainerButton)}>
            {getElement()}
        </div>
    )
}