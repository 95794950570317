import React, {useState} from "react";
import axios from "axios";
import "../../Logindesign.css";
import CustomInput from "../../../../elements/Input/CustomInput";
import DefaultButton from "../../../../elements/Button/DefaultButton";
import {getLanguageContent, TranslateText} from "../../../../libs/LanguageProvider";

/*
        Login Form
        props : {
            showPasswortResetForm: Method to enable the Form for the 'Forget pwd'
            loginformaction: action the form get send to
            swissRxPath: Path redirect to swissRx
        }
 */
const LoginForm = (props) => {
    //tab = 1 -> marketplace login 0 -> SwissRX Login
    const [choosedTab, setTab] = useState(0);

    const [pwd, setpwd] = useState("");
    const [isvalidpwd, setisvalidpwd] = useState(false);

    const [email, setEmail] = useState("");
    const [isvalidemail, setisvalidemail] = useState(false);

    const [sucsessfulLogin, setsucsessfulLogin] = useState(true); // false: Error message for invalid login
    const [disableLoginButton, setDisableLoginButton] = useState(false);

    const selectedTabText = (tab) => {
        return tab
            ? "login-form-tab-text-selected txt-center clickable"
            : "login-form-tab-text-not-selected txt-center clickable";
    };
    const selectedTabUnderline = (tab) => {
        return tab ? "login-form-tab-selected" : "login-form-tab-normal";
    };

    const onKeyPress = (e) => {
        e.persist();
        if (e.charCode !== 13) {
            return;
        }
        handleLogin();
    }

    //console.log('doRedirect', window.doRedirect)

    const defaultRedirect = () => {
        window.location.replace('/home');
    }

    const handleLogin = () => {
        if (email && pwd) {
            setDisableLoginButton(true);
            axios
                .post("/auth/user", {
                    app_login: email,
                    app_password: pwd,
                    app_language: "ENU"
                })
                .then((r) => {
                    setDisableLoginButton(false);
                    if (r.data.hasOwnProperty("token")) {
                        // Success when token is set
                        if (r.data.redirect) {
                            window.location.replace(r.data.redirect);
                        }
                    } else {
                        setsucsessfulLogin(false);
                        props.setsucsessfulLogin(false);
                    }
                });
        } else {
            setisvalidemail(email === "");
            setisvalidpwd(pwd === "");
        }
    };

    const swissRxLogin = () => {
        return (
            <div className="login-mb-2 login-mt-2 center-content grid-one-row">
                <div className="swissRx-description login-mb-2 center-content">
                    {getLanguageContent(
                        "swissRx-description-text",
                        "Durch den Button werden Sie zum SwissRX Button weitergeleitet. " +
                        "Nach einem erfolgreichen Login wird es Ihnen möglich sein im Marketplace einzukaufen."
                    )}
                </div>
                <div className={"flex flex-row place-content-center login-mb-1"}>
                    <a href={props.swissRxPath}>
                        <DefaultButton
                            bSize="xl"
                            title={getLanguageContent("login", "Anmelden")}
                        /></a>
                </div>
            </div>
        )
    }

    return (
        <div className="login-form">
            <div className="login-form-tab">
                <div
                    className={selectedTabText(!choosedTab)}
                    onClick={() => setTab(0)}
                >
                    Marketplace Login
                </div>
                <div
                    className={selectedTabText(choosedTab)}
                    onClick={() => setTab(1)}
                >
                    SwissRX Login
                </div>
                <div className={selectedTabUnderline(!choosedTab)}/>
                <div className={selectedTabUnderline(choosedTab)}/>
            </div>

            {!choosedTab ? (
                <div className="login-mt-2">
                    {!sucsessfulLogin ? (
                        <div className='login-error-card'>
                            <div>
                                <div className='font-bold'>
                                    {TranslateText("validation-login-header")}
                                </div>
                                <div>
                                    {TranslateText("validation-login-content")}
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="login-mb-2 login-mt-2">
                        <CustomInput
                            title="Email"
                            changeEvent={(e) => setEmail(e)}
                            type="text"
                            iName="app_login"
                            iId="inputUsername"
                            iRequired={true}
                            errormessage={TranslateText("validation-input")}
                            validation={isvalidemail}
                            onKeyPress={onKeyPress}
                        />
                    </div>
                    <div className="login-mb-2">
                        <CustomInput
                            title={TranslateText("password")}
                            changeEvent={(e) => setpwd(e)}
                            type="password"
                            iName="app_password"
                            iId="inputPassword"
                            iRequired={true}
                            errormessage={TranslateText("validation-input")}
                            validation={isvalidpwd}
                            onKeyPress={onKeyPress}
                        />
                    </div>
                    <div className="login-form-submit login-mb-3 login-mt-2">
                        <div
                            className="login-form-pwd-forgot clickable"
                            onClick={props.showPasswortResetForm}
                        >
                            {TranslateText("pwreset")}
                        </div>
                        <div className="mr-1">
                            <DefaultButton
                                title={getLanguageContent("login", "Anmelden")}
                                clickevent={handleLogin}
                                disable={disableLoginButton}
                            />
                        </div>
                    </div>
                </div>
            ) : swissRxLogin()}
        </div>
    );
};

export default LoginForm;
