import React from "react";
import {generateCSSString} from "../../helper/styleHelper";


export default function Text(props) {

    const elem = props.elem
    const cssValue = ['paddingTop' , 'paddingBottom', 'paddingRight',  'paddingLeft', 'alignment','textTransform']

    return (
        <div id='Text' className={generateCSSString(elem, cssValue)} style={{color: elem.color}}
             dangerouslySetInnerHTML={{__html: elem.content}}/>
    )
}